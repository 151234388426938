import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_cascader = _resolveComponent("el-cascader");
  return _openBlock(), _createBlock(_component_el_cascader, {
    placeholder: _ctx.placeholder,
    props: _ctx.areaProps,
    options: _ctx.options,
    modelValue: _ctx.cityValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.cityValue = $event),
    onChange: _ctx.handleChange
  }, null, 8, ["placeholder", "props", "options", "modelValue", "onChange"]);
}