import { defineComponent, toRefs, reactive, defineAsyncComponent } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
import API from "@/api/authority/index";
import { useMessage } from '@/hooks/web/useMessage';
import Emitter from "@/eventBus/index";
export default defineComponent({
  emits: ["handleSuccess", "update:visible"],
  components: {
    Eldialog,
    OrganizeSelect: defineAsyncComponent(() => import("@/components/OrganizeSelect/index.vue")),
    AreaSelect: defineAsyncComponent(() => import("@/components/AreaSelect/index.vue")),
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue")),
    Resource: defineAsyncComponent(() => import("./Resource.vue"))
  },
  props: {
    activeOrg: {
      type: Object,
      default: {
        orgPid: null,
        orgName: ''
      }
    },
    orgId: {
      type: Number,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      roleType: [],
      roleOrgId: 1,
      resourceVisible: false,
      resourceRef: null,
      selectAreaValue: [],
      searchValue: "",
      activeValue: null,
      ruleForm: null,
      formData: null,
      title: '新增组织',
      rules: {
        parentId: [{
          required: true,
          message: '请选择上级组织'
        }],
        num: [{
          required: false,
          message: '请输入部门数量'
        }],
        schoolType: [{
          required: true,
          message: '请选择单位类型'
        }],
        educationStage: [{
          required: true,
          message: '请选择阶段'
        }],
        roleId: [{
          required: true,
          message: '请选择组织登录权限',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: "请输入组织名称",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '组织名称不能带有特殊符号'
        }],
        loginName: [{
          required: true,
          message: "请输入登录账号",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '登录账号不能带有特殊符号'
        }],
        province: [{
          required: true,
          message: "请填写所属区域",
          type: 'string',
          trigger: 'change'
        }],
        schoolName: [{
          required: true,
          message: "请输入所属单位",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '所属单位不能带有特殊符号'
        }],
        schoolLoginName: [{
          required: true,
          message: "请输入单位登录账号",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '单位登录账号不能带有特殊符号'
        }]
      },
      confirm() {
        refData.ruleForm.validate(async valid => {
          if (valid) {
            const params = JSON.parse(JSON.stringify(refData.formData));
            const url = props.orgId ? 'updateOrganization' : 'addOrganization';
            const {
              msg,
              code
            } = await API[url](params);
            code == 0 && (emit('handleSuccess'), Emitter.emit('updateOrg'), Emitter.emit('handleShow'));
            message[code == 0 ? 'success' : 'warning'](code == 0 ? `${props.orgId ? '修改' : '添加'}成功` : msg);
          }
        });
      },
      async getData(orgId) {
        const {
          data,
          code,
          msg
        } = await API.getByIdOrganization({
          id: orgId
        });
        if (code == 0) {
          refData.searchValue = data.parentName;
          refData.formData = data;
          refData.selectAreaValue = [data.province, data.city, data.district];
        }
      },
      init() {
        refData.searchValue = '';
        refData.selectAreaValue = [];
        refData.formData = {
          id: null,
          Name: '',
          parentId: null,
          province: '',
          city: '',
          district: '',
          sort: 1,
          remark: '',
          status: 0,
          roleId: 2,
          phone: '',
          loginName: '',
          lon: 0,
          lat: 0
        };
      }
    });
    // 初始化表单
    refData.init();
    //获取当前账号下的全部角色
    const getRole = async () => {
      const {
        data,
        code,
        msg
      } = await API.listRole({
        deptId: refData.formData.parentId ? refData.formData.parentId : 1
      });
      if (code === 0) {
        refData.roleType = data.list.map(item => ({
          value: item.id,
          label: item.name,
          ...item
        }));
      }
    };
    //选择组织
    const handleDoubleClick = data => {
      refData.formData.parentId = data.id;
      getRole();
    };
    //区域赋值
    const handleSelectArea = item => {
      refData.formData.province = item[0];
      refData.formData.city = item[1];
      refData.formData.district = item[2];
    };
    //赋值组织
    const handleAnyway = () => {
      refData.formData.parentId = props.activeOrg.orgPid;
      refData.searchValue = props.activeOrg.orgName;
      refData.activeValue = props.activeOrg.activeValue;
      refData.ruleForm.clearValidate();
      getRole();
    };
    //新增角色
    const handleAddRole = () => {
      if (!refData.formData.parentId) {
        return message.warning('请先选择上级组织');
      }
      refData.resourceVisible = true;
      refData.roleOrgId = refData.formData.parentId;
      refData.resourceRef.isAdd = true;
    };
    // 查看权限
    const visibleChange = (roleOrgId, roleType) => {
      if (!roleOrgId) return message.warning('请先选择上级组织');
      refData.resourceVisible = true;
      refData.roleOrgId = roleOrgId;
      refData.resourceRef.type = refData.roleType.filter(item => roleType === item.value);
      refData.resourceRef.isAdd = false;
    };
    return {
      ...toRefs(refData),
      handleDoubleClick,
      handleSelectArea,
      visibleChange,
      handleAnyway,
      handleAddRole,
      getRole
    };
  }
});