import { defineComponent, nextTick, watch, reactive, computed, toRefs } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  props: {
    inpusStyle: {
      type: Object,
      default: () => {
        return {
          width: "250px"
        };
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return '请选择';
      }
    },
    inputValue: {
      type: Array,
      default: null
    }
  },
  emits: ["handleChange", "update:inputValue"],
  setup(props, {
    emit
  }) {
    const store = useStore();
    const refData = reactive({
      options: [],
      citys: [],
      // cityValue: [] as any,
      areaProps: {
        value: 'code',
        label: 'name',
        children: 'list'
      },
      handleChange: function (val) {
        emit("handleChange", val);
      }
    });
    const cityValue = computed({
      get: () => props.inputValue,
      set: val => {
        emit("update:inputValue", val);
      }
    });
    nextTick(() => {
      refData.options = store.state.areaData;
      watch(() => props.inputValue, val => {
        if (props.inputValue == []) {
          cityValue.value = [];
        }
      }, {
        immediate: true,
        deep: true
      });
    });
    return {
      cityValue,
      ...toRefs(refData),
      ...toRefs(props)
    };
  }
});